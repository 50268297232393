// @flow strict

import type { Node } from 'react';
import cx from 'classnames';

type Props = {
  height?: string,
  className?: string,
  color?: string,
};

const Divider = function ({
  className,
  height,
  color = '#575654',
}: Props): Node {
  return (
    <div
      className={cx('divider', className)}
      style={{ height, backgroundColor: color }}
    />
  );
};

export default Divider;

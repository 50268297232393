// @flow strict

import type { Node } from 'react';
import { useCallback, useEffect, useState } from 'react';

import Filter from './Filter';
import List from './List';
import Paginator from '../../../molecules/paginator';

import { useAccountDocuments } from '../../../hooks';

type Props = {
  accountId: number,
};

const DOCUMENT_TYPE_DEFAULT = '3';

const DocumentList = function ({ accountId }: Props): Node {
  const [documentType, setDocumentType] = useState(DOCUMENT_TYPE_DEFAULT);
  const [tag, setTag] = useState();
  const [page, setPage] = useState(1);

  const { data, loading } = useAccountDocuments(
    accountId,
    { documentType, tag },
    { page },
  );

  const { data: documents, pageInfo } = data || {};

  const onChangeFilter = useCallback((filter) => {
    setDocumentType(filter.documentType);
    setTag(filter.tag);
    setPage(1);
  }, []);

  useEffect(() => {
    setDocumentType(DOCUMENT_TYPE_DEFAULT);
    setTag(undefined);
    setPage(1);
  }, [accountId]);

  return (
    <div>
      <Filter documentType={documentType} tag={tag} onChange={onChangeFilter} />
      <List documents={documents} loading={loading} />
      <Paginator
        currentPage={page}
        totalPages={pageInfo.totalPages}
        totalItems={pageInfo.totalItems}
        onChange={setPage}
      />
    </div>
  );
};

export default DocumentList;

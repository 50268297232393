// @flow strict

import type { Node } from 'react';
import Select, { components } from 'react-select';

import { BiSearchAlt2 } from './icons';

type Option = {| label: string, value: string | number |};

type Props = {|
  onChange?: (option: ?Option) => void,
  options?: Option[],
  placeholder?: string,
|};

const DropdownIndicator = function (props) {
  return (
    <components.DropdownIndicator {...props}>
      <BiSearchAlt2 size={28} />
    </components.DropdownIndicator>
  );
};

const CityAutocomplete = function ({
  onChange,
  options,
  placeholder,
}: Props): Node {
  return (
    <Select
      className="autocomplete-city"
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      components={{
        IndicatorSeparator: null,
        DropdownIndicator,
      }}
      styles={{
        dropdownIndicator: (provided) => ({
          ...provided,
          color: 'inherit',
          '&:hover': {
            color: 'inherit',
          },
        }),
        valueContainer: (provided) => ({
          ...provided,
        }),
        control: (provided) => ({
          ...provided,
          borderColor: 'transparent',
          boxShadow: 'none',
          '&:hover': {
            borderColor: 'transparent',
          },
        }),
        placeholder: (provided) => ({
          ...provided,
          color: 'inherit',
          fontWeight: 'bold',
          marginLeft: 0,
          marginRight: 0,
        }),
        singleValue: (provided) => ({
          ...provided,
          color: 'inherit',
          fontWeight: 'bold',
          textTransform: 'uppercase',
        }),
        menu: (provided) => ({
          ...provided,
          borderColor: 'transparent',
          boxShadow: 'none',
        }),
        option: (provided) => ({
          ...provided,
          backgroundColor: '#fff',
          color: 'inherit',
          '&:hover': {
            backgroundColor: '#ddd',
          },
          ':active': {
            backgroundColor: '#ddd',
          },
        }),
      }}
    />
  );
};

export default CityAutocomplete;

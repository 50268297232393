// @flow strict

import type { Node } from 'react';
import { useEffect } from 'react';
import { Col, Row } from 'reactstrap';

import Divider from '../atoms/Divider';
import Base from '../templates/Base';
import { PUBLIC_URL } from '../config';

const LinksPage = function (): Node {
  useEffect(() => {
    document.title = 'Cabildo Abierto - Enlaces de Interés';
  }, []);

  return (
    <Base>
      <h5 className="fw-bold">ENLACES DE INTERÉS</h5>
      <Divider className="my-3" />
      <Row>
        <Col md="3">
          <img
            alt="Quién me representa"
            className="img-fluid mb-4"
            src={`${PUBLIC_URL}/img/home.png`}
          />
          <h5 className="fw-bold mb-3">QUIÉN ME REPRESENTA</h5>
          <p>
            Busca acercar al público en Puerto Rico a sus representantes electos
            a nivel federal, estatal y municipal, primero identificando quiénes
            son las personas que nos representan según nuestro lugar de
            residencia, y segundo, facilitando cómo contactarlos vía teléfono,
            correo electrónico o redes.
          </p>
          <p>
            La plataforma digital{' '}
            <a
              href="https://www.quienmerepresentapr.com"
              target="_blank"
              rel="noreferrer"
              style={{ color: 'inherit' }}
            >
              https://www.quienmerepresentapr.com
            </a>{' '}
            fue creada por Espacios Abiertos en 2020 y reconocida en 2021 como
            Webby Award Honoree en Innovación Cívica y Gobierno por la Academia
            Internacional de la Ciencia y Arte Digital.
          </p>
          <p>
            Quien Me Representa aporta a un Puerto Rico más democrático, donde
            los representantes electos puedan rendir cuentas a sus
            representados, y se instrumenten con justicia y equidad las
            necesidades y prioridades de la mayoría de los ciudadanos y
            ciudadanas, no de particulares.
          </p>
        </Col>
        <Col md="3">
          <img
            alt="Espacios Abiertos"
            className="img-fluid mb-4"
            src={`${PUBLIC_URL}/img/home.png`}
          />
          <h5 className="fw-bold mb-3">OBSERVATORIO FISCAL</h5>
          <p>
            Con mejor conocimiento todas y todos, el sector público y el sector
            privado, podemos colaborar para incidir efectivamente en las
            decisiones sobre el uso de los recursos y fondos públicos. Para ello
            y a los fines de democratizar la información financiera y fiscal de
            Puerto Rico, Espacios Abiertos desarrolló la plataforma del
            Observatorio Fiscal .
          </p>
          <p>
            Esta herramienta digital hace visible la información sobre el
            presupuesto estatal y los presupuestos municipales y facilita su
            acceso tanto al experto en temas de economía como a la ciudadanía en
            general, a través de paneles interactivos de datos que contienen
            sobre 50 mil campos de datos, extraídos de más de 3,000 documentos
            oficiales del gobierno y que anteriormente solo estaban disponibles
            en formato PDF.
          </p>
          <p>
            El Observatorio{' '}
            <a
              href="https://observatoriofiscalpr.com"
              target="_blank"
              rel="noreferrer"
              style={{ color: 'inherit' }}
            >
              https://observatoriofiscalpr.com
            </a>{' '}
            permite al usuario realizar diversidad de búsquedas, incluyendo el
            historial y detalle por área temática, categoría de gasto o
            programa, así como identificar partidas y fuentes de ingresos de
            todas las agencias públicas y de cada uno de los 78 municipios desde
            el 2006 hasta el presente, hacer comparaciones, bajar la información
            en formato de gráfica o tabla, entre otras funciones.
          </p>
        </Col>
        <Col md="3">
          <img
            alt="Espacios Abiertos"
            className="img-fluid mb-4"
            src={`${PUBLIC_URL}/img/home.png`}
          />
          <h5 className="fw-bold mb-3">EL LABORATORIO COMUNITARIO</h5>
          <p>
            El Laboratorio Comunitario es un repositorio digital desarrollado
            para servir de referencia al trabajo de líderes y lideresas en sus
            comunidades. La base del Laboratorio son 8 módulos de valor práctico
            y didáctico diseñados con el liderato comunitario como parte de un
            ciclo de talleres y encuentros, a lo largo de 18 meses luego del
            primer aniversario del huracán María.
          </p>
          <p>
            La información contenida en la plataforma procura respaldar el
            trabajo del líder comunitario y propiciar la atención a situaciones
            que impactan o podrían impactar a su vecindario, y generar cambios
            cuando así se necesiten. Para explorar el proyecto digital acceda{' '}
            <a
              href="https://laboratoriocomunitario.com"
              target="_blank"
              rel="noreferrer"
              style={{ color: 'inherit' }}
            >
              https://laboratoriocomunitario.com
            </a>
            . Le exhortamos a compartir el enlace con otros líderes y lideresas.
          </p>
        </Col>
        <Col md="3">
          <img
            alt="Espacios Abiertos"
            className="img-fluid mb-4"
            src={`${PUBLIC_URL}/img/home.png`}
          />
          <h5 className="fw-bold mb-3">
            SUTRA - Sistema Único de Trámite Legislativo de la Oficina de
            Servicios Legislativos
          </h5>
          <p>
            Plataforma de trámite legislativo de la Oficina de Servicios
            Legislativos del Capitolio, oficina responsable, entre otras cosas,
            de recopilar, integrar y mantener un registro actualizado de todas
            las medidas legislativas.
          </p>
          <p>
            A través de SUTRA la Oficina de Trámite Legislativo de la OSL
            facilita la búsqueda de todas las medidas legislativas presentadas
            en la Cámara de Representantes y el Senado desde 1992, leyes, y
            algunas ordenanzas municipales recientes. SUTRA{' '}
            <a
              href="https://sutra.oslpr.org"
              target="_blank"
              rel="noreferrer"
              style={{ color: 'inherit' }}
            >
              https://sutra.oslpr.org
            </a>{' '}
            permite al usuario conocer el último trámite de una medida,
            incluyendo si se convirtió o no en ley.
          </p>
        </Col>
      </Row>
    </Base>
  );
};

export default LinksPage;

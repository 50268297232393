// @flow strict

import type { Node } from 'react';
import { useState, useCallback, useMemo } from 'react';

import HeroHeader from '../organisms/partials/HeroHeader';
import MainContent from '../organisms/partials/MainContent';
import FixedSidebar from '../organisms/partials/sidebar/FixedSidebar';
import MenuContext from '../context/Menu';
import MenuModal from '../organisms/MenuModal';
import Footer from '../organisms/partials/Footer';

type Props = {
  backgroundImage?: string,
  children?: Node,
};

const Hero = function ({ backgroundImage, children }: Props): Node {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = useCallback(() => {
    setIsMenuOpen((current) => !current);
  }, []);

  const menu = useMemo(() => {
    return { isOpen: isMenuOpen, toggle: toggleMenu };
  }, [isMenuOpen, toggleMenu]);

  return (
    <MenuContext.Provider value={menu}>
      <div id="hero" style={{ backgroundImage }}>
        <HeroHeader />
        <FixedSidebar />
        <MainContent>{children}</MainContent>
        <Footer />
        <MenuModal isOpen={isMenuOpen} toggle={toggleMenu} />
      </div>
    </MenuContext.Provider>
  );
};

export default Hero;

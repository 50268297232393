// @flow strict

import type { Node } from 'react';

import Slider from 'react-slick';
import Avatar from '../../atoms/Avatar';
import { BiChevronLeft, BiChevronRight } from '../../atoms/icons';
import AvatarItem from '../../molecules/list-item/AvatarItem';

import type { Legislator } from '../../types';

type Props = {
  legislators: Array<Legislator>,
};

type Arrow = {
  className?: string,
  onClick?: () => void,
};

function PrevArrow({ className, onClick }: Arrow) {
  return (
    <button type="button" className={className} onClick={onClick}>
      <BiChevronLeft />
    </button>
  );
}

function NextArrow({ className, onClick }: Arrow) {
  return (
    <button type="button" className={className} onClick={onClick}>
      <BiChevronRight />
    </button>
  );
}

const Settings = {
  dots: false,
  infinite: false,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 1120,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const LegislatorCarousel = function ({ legislators }: Props): Node {
  return (
    <div className="mb-4">
      <Slider {...Settings} className="slider-cabildo">
        {legislators.map((item) => (
          <div key={item.id} className="pe-3">
            <a
              href={item.url}
              target="_blank"
              rel="noreferrer"
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              <AvatarItem
                title={item.name}
                description={
                  item.gender === 'F' ? 'legisladora municipal' : item.position
                }
                avatar={
                  <Avatar
                    src={item.avatar}
                    shape="circle"
                    size="medium"
                    className={
                      item.party != null
                        ? `avatar-${item.party.toLowerCase()}`
                        : ''
                    }
                  />
                }
              />
            </a>
          </div>
        ))}
      </Slider>
      {legislators.length === 0 && (
        <p>Aún no tenemos datos de los legisladores para este municipio.</p>
      )}
    </div>
  );
};

export default LegislatorCarousel;

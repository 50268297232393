// @flow strict

import type { Node } from 'react';
import { Col, Row } from 'reactstrap';

import Line from '../../atoms/shimmer/Line';

const Town = function (): Node {
  return (
    <div>
      <Row className="mb-3">
        <Col lg={7}>
          <Line height="30px" className="mb-4" />
          <Line height="20px" className="mb-3" />
          <Line height="20px" className="mb-3" />
          <Line height="20px" className="mb-3" />
          <Line height="20px" className="mb-4" />
        </Col>
        <Col lg={5}>
          <Line height="180px" className="mb-4" />
        </Col>
      </Row>
      <Line height="60px" className="mb-4" />
    </div>
  );
};

export default Town;

// @flow strict
import type { Element } from 'react';
import cx from 'classnames';

type Props = {
  className?: string,
  round?: boolean,
  height?: string,
  width?: string,
};

const ShimmerLine = function ({
  className,
  round,
  height,
  width,
}: Props): Element<'div'> {
  return (
    <div
      className={cx(className, 'shimmer', 'shimmer-line', {
        'shimmer-round': round,
      })}
      style={{ height, width }}
    />
  );
};

export default ShimmerLine;

// @flow strict

import type { Node } from 'react';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavbarText,
  NavbarToggler,
} from 'reactstrap';
import LogoMobile from '../../atoms/LogoMobile';
import CityAutocomplete from '../../atoms/CityAutocomplete';
import { BiMenu } from '../../atoms/icons';
import { useCities } from '../../hooks';
import MenuContext from '../../context/Menu';

const HeroHeader = function (): Node {
  const navigate = useNavigate();
  const { data: cities } = useCities();
  const { toggle } = useContext(MenuContext);

  const cityOptions = cities?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const handleOnChangeCity = useCallback(
    (option) => {
      if (option?.value != null) {
        navigate(`/town/${option.value}`);
      }
    },
    [navigate],
  );

  return (
    <header id="header-hero" className="fixed-top">
      <Navbar>
        <Nav
          className="flex-grow-1"
          style={{ paddingBottom: '0.3rem', paddingTop: '0.3rem' }}
        >
          <NavItem className="d-block d-md-none d-lg-none">
            <NavbarBrand tag="div">
              <LogoMobile secondary />
            </NavbarBrand>
          </NavItem>
        </Nav>
        <NavbarText style={{ paddingBottom: '0.3rem', paddingTop: '0.3rem' }}>
          {/*
          <NavbarToggler onClick={toggle}>
            <BiAdjust size={32} />
          </NavbarToggler>
          */}
          <NavbarToggler onClick={toggle}>
            <BiMenu size={32} />
          </NavbarToggler>
        </NavbarText>
      </Navbar>
      <div className="px-2 d-block d-md-none d-lg-none">
        <CityAutocomplete
          onChange={handleOnChangeCity}
          options={cityOptions}
          placeholder="Busca tu Municipio"
        />
      </div>
    </header>
  );
};

export default HeroHeader;

// @flow strict

import type { Node } from 'react';
import { useEffect } from 'react';
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Col,
  Row,
  UncontrolledAccordion,
} from 'reactstrap';

import Divider from '../atoms/Divider';
import Base from '../templates/Base';

const AboutUsPage = function (): Node {
  useEffect(() => {
    document.title = 'Cabildo Abierto - Preguntas Frecuentes';
  }, []);

  return (
    <Base>
      <h5 className="fw-bold">PREGUNTAS FRECUENTES</h5>
      <Divider className="mt-3 mb-0" />
      <Row>
        <Col md="6" />
        <Col md="6">
          <UncontrolledAccordion
            className="accordion-cabildo"
            defaultOpen="1"
            stayOpen
          >
            <AccordionItem className="faq-accordion-item">
              <AccordionHeader targetId="1">
                ¿Qué es un cabildo? ¿Por qué hablamos de cabildo abierto?
              </AccordionHeader>
              <AccordionBody accordionId="1">
                <p>
                  Hoy en día los cabildos se conocen como ayuntamientos o
                  municipios. En tiempos de España, el término era utilizado
                  para nombrar al organismo político que tenía jurisdicción
                  sobre los asuntos de una región o ciudad. Los cabildos podían
                  ser cerrados o abiertos. Eran cerrados cuando solamente
                  participaban de las sesiones los “cabildantes”, y eran
                  abiertos cuando participaban con voz y voto todos los vecinos
                  de la comunidad. Vale la pena destacar que aquellos tiempos el
                  término vecino no incluía a todo el mundo, sino que excluía a
                  pobres, indígenas, esclavos y mujeres. Con este proyecto
                  buscamos promover que ahora todos y todas sean partícipes del
                  acontecer y decisiones que se toman a nivel de gobierno
                  municipal.
                </p>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem className="faq-accordion-item">
              <AccordionHeader targetId="2">
                ¿Por qué debemos conocer a nuestra legislatura municipal?
              </AccordionHeader>
              <AccordionBody accordionId="2">
                <p>
                  Más allá de la figura del alcalde o alcaldesa como funcionario
                  ejecutivo municipal, los 78 municipios en Puerto Rico eligen
                  un cuerpo legislativo para su representación. La cantidad de
                  miembros de las legislaturas municipales varía, según el
                  tamaño del municipio, siendo la legislatura municipal de San
                  Juan la más grande con 17 miembros, y la de Culebra la más
                  pequeña con 5 miembros.
                </p>
                <p>
                  Los legisladores municipales son los representantes del
                  pueblo, y hayamos votado por esa persona o no, o inclusive sin
                  haber participado en la elección, como sus constituyentes
                  tenemos el derecho a que se escuche nuestra opinión y a exigir
                  cuentas sobre las decisiones que éstos toman.
                </p>
                <p>
                  Por eso es importante saber quiénes son, cuándo se reúnen,
                  dónde y cómo contactarlos.
                </p>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem className="faq-accordion-item">
              <AccordionHeader targetId="3">
                ¿Cómo participo en una vista pública? ¿Cómo preparar una
                ponencia?
              </AccordionHeader>
              <AccordionBody accordionId="3">
                <p>
                  Los organismos gubernamentales celebran vistas públicas para
                  ofrecer oportunidad a la ciudadanía de expresarse sobre
                  distintos asuntos. En muchos casos las vistas son requisito de
                  ley. Algunas instancias de vistas pueden incluir la consulta
                  sobre la construcción de un proyecto público o privado, la
                  consideración de una medida legislativa u ordenanza municipal,
                  la aprobación de un plan o reglamento, entre otras. Los
                  anuncios de vistas públicas se publican en los diarios de
                  circulación general o en las páginas web/redes de las agencias
                  o municipios. Para participar debemos estar atentos o hacer la
                  diligencia de llamar y preguntar. Esta página pretende
                  facilitar alguna de esa información en los calendarios de cada
                  municipio. Para acceder a las guías para la redacción de
                  ponencias oprime este{' '}
                  <a
                    href="http://espaciosabiertos.org/wp-content/uploads/guia-participacion-vistas-publicas-redaccion-ponencias.pdf"
                    rel="noreferrer"
                    target="_blank"
                    style={{ color: 'inherit' }}
                  >
                    enlace.
                  </a>
                </p>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem className="faq-accordion-item">
              <AccordionHeader targetId="4">
                ¿De dónde emana el poder político de los representantes electos?
              </AccordionHeader>
              <AccordionBody accordionId="4">
                <p>
                  Del pueblo. El preámbulo de la Constitución de Puerto Rico
                  establece:
                </p>
                <p>
                  “Que el sistema democrático es fundamental para la vida de la
                  comunidad puertorriqueña; Que entendemos por sistema
                  democrático aquel donde la voluntad del pueblo es la fuente
                  del poder público, donde el orden político está subordinado a
                  los derechos del hombre y donde se asegura la libre
                  participación del ciudadano en las decisiones colectivas.”
                </p>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem className="faq-accordion-item">
              <AccordionHeader targetId="5">
                ¿Qué rama de gobierno representa de forma más directa a la
                gente?
              </AccordionHeader>
              <AccordionBody accordionId="5">
                <p>
                  La rama legislativa. En Puerto Rico los siguientes
                  funcionarios electos son parte de las ramas legislativas: los
                  senadores y los representantes representan al pueblo ante la
                  legislatura estatal, los asambleístas/legisladores municipales
                  nos representan ante la legislatura municipal, y el
                  comisionado o comisionada residente, nos representa ante el
                  Congreso de los EEUU. Como representantes del pueblo, todos,
                  tienen el deber de escuchar y actuar en defensa de los mejores
                  intereses de sus constituyentes, no de ningún partido o líder
                  político.
                </p>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem className="faq-accordion-item">
              <AccordionHeader targetId="6">
                ¿Participación ciudadana y rendición de cuentas?
              </AccordionHeader>
              <AccordionBody accordionId="6">
                <p>
                  La rendición de cuentas y participación ciudadana son
                  elementos básicos de una democracia. El ejercicio democrático
                  ciudadano no termina en la caseta de votación, ni queda en el
                  aire durante los cuatro años siguientes a una elección. La
                  participación ciudadana, así como la rendición de cuentas son
                  ejercicios diarios. Para una participación efectiva hace falta
                  conocimiento informado y acceso a los que toman decisiones.
                </p>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem className="faq-accordion-item">
              <AccordionHeader targetId="7">
                ¿Cómo se consolidan o suprimen municipios, quién lo decide?
              </AccordionHeader>
              <AccordionBody accordionId="7">
                <p>
                  El artículo VI de la Constitución de Puerto Rico establece que
                  la Asamblea Legislativa tendrá la facultad para crear,
                  suprimir, consolidar y reorganizar municipios, modificar sus
                  límites territoriales y determinar lo relativo a su régimen y
                  función; y podrá autorizarlos, además, a desarrollar programas
                  de bienestar general y crear aquellos organismos que fueren
                  necesarios a tal fin.
                </p>
                <p>
                  Ninguna ley para suprimir o consolidar municipios tendrá
                  efectividad hasta que sea ratificada, en referéndum, por la
                  mayoría de los electores capacitados que participen en el
                  mismo en cada uno de los municipios a suprimirse o
                  consolidarse.
                </p>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem className="faq-accordion-item">
              <AccordionHeader targetId="8">
                ¿Qué es el Código Municipal?
              </AccordionHeader>
              <AccordionBody accordionId="8">
                <p>
                  El Código Municipal o Ley Núm. 107 de 14 de agosto de 2020,
                  integra, organiza y actualiza en un mismo volumen todas las
                  leyes que disponían sobre la organización, administración y
                  funcionamiento de los municipios en Puerto Rico.
                </p>
              </AccordionBody>
            </AccordionItem>
            <AccordionItem className="faq-accordion-item">
              <AccordionHeader targetId="9">
                ¿Cómo se convierte un proyecto de ordenanza en “ley”?
              </AccordionHeader>
              <AccordionBody accordionId="9">
                <p>
                  Se presenta ante Secretario o Secretaria Municipal quien lo
                  registra y remite al Presidente de la Legislatura Municipal
                  para incluirse en la agenda de la sesión ordinaria. El
                  proyecto de ordenanza se aprueba con el voto afirmativo de la
                  mayoría del número total de los miembros que componen la
                  Legislatura Municipal y se hace efectiva la ordenanza en la
                  fecha en que el alcalde la firma. El alcalde tiene también la
                  potestad de objetar y devolver un proyecto de ordenanza. Las
                  resoluciones municipales siguen el mismo trámite de las
                  ordenanzas, salvo las que tienen que ver con asuntos internos
                  de la Legislatura Municipal, que no requieren la aprobación
                  del alcalde.
                </p>
              </AccordionBody>
            </AccordionItem>
          </UncontrolledAccordion>
        </Col>
      </Row>
    </Base>
  );
};

export default AboutUsPage;

// @flow strict

import type { Node } from 'react';
import { useEffect } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Col,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';

import { BiShareAlt } from '../atoms/icons';
import Divider from '../atoms/Divider';
import PuertoRico from '../atoms/map/PuertoRico';
import Avatar from '../atoms/Avatar';

import AvatarItem from '../molecules/list-item/AvatarItem';
import TownShimmer from '../molecules/shimmer/Town';

import QueryResult from './QueryResult';
import LegislatorCarousel from './carousel/Legislator';

import { useAccounts, useTown } from '../hooks';

type Props = { id: number };

const Town = function ({ id }: Props): Node {
  const { loading, data, error } = useTown(parseInt(id, 10));
  const { data: accounts } = useAccounts();

  const { name, mayor, legislators } = data || { mayor: {}, legislators: [] };

  useEffect(() => {
    if (name != null && name !== '') {
      document.title = `Cabildo Abierto - ${name}`;
    }
  }, [name]);

  return (
    <QueryResult
      loading={loading}
      loadingElement={<TownShimmer />}
      error={error}
    >
      <div>
        <Row className="my-3">
          <Col lg={6}>
            <h1 className="fw-bold display-5 my-3">{name}</h1>
            <div className="mb-3">
              <UncontrolledDropdown>
                <DropdownToggle color="purple">
                  <BiShareAlt /> Compartir
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem tag="div">
                    <a
                      className="d-block"
                      href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(
                        window.location.href,
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'inherit', textDecoration: 'none' }}
                    >
                      Facebook
                    </a>
                  </DropdownItem>
                  <DropdownItem tag="div">
                    <a
                      className="d-block"
                      href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                        window.location.href,
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'inherit', textDecoration: 'none' }}
                    >
                      Twitter
                    </a>
                  </DropdownItem>
                  <DropdownItem tag="div">
                    <a
                      className="d-block"
                      href={`whatsapp://send?text=${window.location.href}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'inherit', textDecoration: 'none' }}
                    >
                      Whatsapp
                    </a>
                  </DropdownItem>
                  <DropdownItem tag="div">
                    <a
                      className="d-block"
                      href={`mailto:?subject=${encodeURIComponent(
                        'Cabildo Abierto',
                      )}&body=${encodeURIComponent('Participa!')}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'inherit', textDecoration: 'none' }}
                    >
                      Email
                    </a>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </Col>
          <Col lg={6}>
            <PuertoRico accounts={accounts} selectedLocation={name} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col xs={6} md={6} lg={3}>
            <h5 className="fw-bold mb-4 d-none d-md-block d-lg-block">
              EJECUTIVO MUNICIPAL
            </h5>
            <h5 className="fw-bold mb-4 d-block d-md-none d-lg-none">
              E. MUNICIPAL
            </h5>
            <a
              href={mayor.url}
              target="_blank"
              rel="noreferrer"
              style={{ color: 'inherit', textDecoration: 'none' }}
            >
              <AvatarItem
                title={mayor.name}
                description={
                  mayor.gender === 'F' ? 'alcaldesa' : mayor.position
                }
                avatar={
                  <Avatar
                    src={mayor.avatar}
                    shape="circle"
                    size="medium"
                    className={
                      mayor.party != null
                        ? `avatar-${mayor.party.toLowerCase()}`
                        : ''
                    }
                  />
                }
              />
            </a>
          </Col>
          <Col xs={6} md={6} lg={9}>
            <h5 className="fw-bold mb-4 d-none d-md-block d-lg-block">
              LEGISLATURA MUNICIPAL
            </h5>
            <h5 className="fw-bold mb-4 d-block d-md-none d-lg-none">
              L. MUNICIPAL
            </h5>
            <LegislatorCarousel legislators={legislators} />
          </Col>
        </Row>
      </div>
    </QueryResult>
  );
};

export default Town;

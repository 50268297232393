// @flow strict

import type { Node } from 'react';
import moment from 'moment';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledDropdown,
} from 'reactstrap';
import { BiShareAlt } from '../../atoms/icons';
import { API_DATE_TIME_FORMAT } from '../../constants';

type Props = {
  name: string,
  description: string,
  from: string,
  to: string,
  isOpen: boolean,
  toggle?: ?() => void,
};

const EventModal = function ({
  name,
  description,
  from,
  to,
  isOpen,
  toggle,
}: Props): Node {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="modal-event" size="lg">
      <ModalHeader
        close={
          <UncontrolledDropdown>
            <DropdownToggle color="light">
              <BiShareAlt size={28} />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem>
                <a
                  href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(
                    window.location.href,
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'inherit', textDecoration: 'none' }}
                >
                  Facebook
                </a>
              </DropdownItem>
              <DropdownItem>
                <a
                  href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                    window.location.href,
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'inherit', textDecoration: 'none' }}
                >
                  Twitter
                </a>
              </DropdownItem>
              <DropdownItem>
                <a
                  href={`whatsapp://send?text=${window.location.href}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'inherit', textDecoration: 'none' }}
                >
                  Whatsapp
                </a>
              </DropdownItem>
              <DropdownItem>
                <a
                  href={`mailto:?subject=${encodeURIComponent(
                    'Cabildo Abierto',
                  )}&body=${encodeURIComponent('Participa!')}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'inherit', textDecoration: 'none' }}
                >
                  Email
                </a>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        }
      >
        {moment(from, API_DATE_TIME_FORMAT).format('DD')}
        <span>{moment(from, API_DATE_TIME_FORMAT).format('MMM')}</span>
      </ModalHeader>
      <ModalBody>
        <h2>
          {name}
          <span>
            {moment(from, API_DATE_TIME_FORMAT).format('h a')}
            {' - '}
            {moment(to, API_DATE_TIME_FORMAT).format('h a')}
          </span>
        </h2>
        <p>{description}</p>
      </ModalBody>
    </Modal>
  );
};

export default EventModal;

// @flow strict

import type { Node } from 'react';
import { useCallback } from 'react';
import moment from 'moment';
import ICalendarLink from 'react-icalendar-link';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

import {
  BiDocument,
  BiDotsVerticalRounded,
  BiDownload,
  BiCalendarPlus,
} from '../../atoms/icons';
import type { Attachment, Event } from '../../types';
import { API_DATE_TIME_FORMAT } from '../../constants';

type Props = {
  id: number,
  name: string,
  description: string,
  from: string,
  to: string,
  status: number,
  attachments: ?Array<Attachment>,
  onClick?: (event: Event) => void,
};

const EventItem = function ({
  id,
  name,
  description,
  from,
  to,
  status,
  attachments,
  onClick,
}: Props): Node {
  const handleOnClick = useCallback(() => {
    onClick?.({
      id,
      name,
      description,
      from,
      to,
      status,
      attachments: [],
      location: '',
    });
  }, [id, name, description, from, to, status, onClick]);

  return (
    <div className="event-row">
      <div className="event-date">
        <h1>
          {moment(from, API_DATE_TIME_FORMAT).format('DD')}
          <span>{moment(from, API_DATE_TIME_FORMAT).format('MMM')}</span>
        </h1>
      </div>
      <div className="event-body">
        <h4>{name}</h4>
        <p>
          {moment(from, API_DATE_TIME_FORMAT).format('h a')} -{' '}
          {moment(to, API_DATE_TIME_FORMAT).format('h a')}
        </p>
      </div>
      <div className="event-actions">
        <div className="d-block d-sm-none">
          <UncontrolledDropdown setActiveFromChild>
            <DropdownToggle tag="a">
              <BiDotsVerticalRounded size={28} />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={handleOnClick}>Ver Más</DropdownItem>
              <DropdownItem tag="div">
                <ICalendarLink
                  filename={name}
                  event={{
                    title: name,
                    description,
                    startTime: from,
                    endTime: to,
                  }}
                  className="text-decoration-none"
                >
                  Añadir a iCal
                </ICalendarLink>
                {attachments && attachments.length > 0 && (
                  <a
                    className="d-block text-decoration-none"
                    href={attachments[0].url}
                    rel="noreferrer"
                    target="_blank"
                  >
                    Descargar
                  </a>
                )}
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <div className="d-none d-sm-block">
          <BiDocument role="button" size={30} onClick={handleOnClick} />
          <ICalendarLink
            filename={name}
            event={{
              title: name,
              description,
              startTime: from,
              endTime: to,
            }}
          >
            <BiCalendarPlus role="button" size={30} className="ms-3" />
          </ICalendarLink>
          {attachments && attachments.length > 0 && (
            <a href={attachments[0].url} rel="noreferrer" target="_blank">
              <BiDownload role="button" size={30} className="ms-3" />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventItem;

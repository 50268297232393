// @flow strict

import type { Node } from 'react';

import { PUBLIC_URL } from '../config';

type Props = {
  secondary?: boolean,
  width?: string | number,
};

const LogoEspaciosAbiertos = function ({
  secondary = false,
  width,
}: Props): Node {
  const logo = secondary
    ? 'espacios-abiertos-alternative'
    : 'espacios-abiertos-alternative';
  const logoPath = `${PUBLIC_URL}/img/${logo}`;

  return (
    <a href="https://espaciosabiertos.org" rel="noreferrer" target="_blank">
      <img
        src={`${logoPath}.png`}
        alt="Espacios abiertos"
        className="img-fluid"
        srcSet={`${logoPath}@2x.png 2x, ${logoPath}@3x.png 3x`}
        width={width}
      />
    </a>
  );
};

export default LogoEspaciosAbiertos;

// @flow strict

import type { Node } from 'react';
import { useCallback } from 'react';

import { useDocumentTypes, useTags } from '../../../hooks';
import Line from '../../../atoms/shimmer/Line';
import DocumentTypeItem from '../../../molecules/list-item/DocumentTypeItem';

type Props = {
  documentType?: string,
  tag?: string,
  onChange?: (value: { documentType: string, tag: string }) => void,
};

const DocumentFilterList = function ({
  documentType = '',
  tag = '',
  onChange,
}: Props): Node {
  const { data, loading } = useDocumentTypes();
  const { data: tags } = useTags();

  const handleOnChangeDocumentType = useCallback(
    (value) => {
      onChange?.({ documentType: value, tag });
    },
    [onChange, tag],
  );

  const handleOnChangeTag = useCallback(
    (event) => {
      onChange?.({ documentType, tag: event.target.value || undefined });
    },
    [onChange, documentType],
  );

  if (loading) {
    return (
      <div className="document-type-list">
        <Line height="10px" className="mb-2" />
      </div>
    );
  }

  return (
    <div className="d-flex justify-content-between document-filter">
      <div className="document-type-list-wrapper">
        <ul className="list-inline document-type-list">
          {data?.map((item) => (
            <DocumentTypeItem
              key={item.id}
              id={item.id}
              name={item.name}
              onClick={handleOnChangeDocumentType}
              active={item.id.toString() === documentType}
            />
          ))}
        </ul>
      </div>
      <div>
        <select
          className="form-select"
          onChange={handleOnChangeTag}
          value={tag}
        >
          <option value="">Filtrar por tema</option>
          {tags?.map((item) => (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default DocumentFilterList;

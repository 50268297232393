// @flow strict

import { createContext } from 'react';

// $FlowFixMe
const MenuContext = createContext({
  isOpen: false,
  toggle: () => {},
});

export default MenuContext;

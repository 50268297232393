// @flow strict

import axios from 'axios';
import { API_ACCESS_TOKEN, API_BASE_URL } from './config';

// $FlowFixMe
const apiClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Authorization: `Bearer ${API_ACCESS_TOKEN}`,
  },
});

export default apiClient;

// @flow strict

import type { Node } from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PuertoRicoMap from '@svg-maps/puerto-rico';
import { SVGMap } from 'react-svg-map';
import slugify from 'slugify';

import type { Account } from '../../types';

type Props = {
  accounts?: ?Array<Account>,
  selectedLocation?: ?string,
};

const PuertoRico = function ({ accounts = [], selectedLocation }: Props): Node {
  const navigate = useNavigate();

  const handleOnClick = useCallback(
    (e) => {
      const selectedAccount = accounts?.find(
        (item) =>
          slugify(item.city.name.toLowerCase()) === slugify(e.target.id),
      );
      if (selectedAccount != null) {
        navigate(`/town/${selectedAccount.id}`);
      }
    },
    [accounts, navigate],
  );

  const locationClassName = useCallback(
    (location) => {
      const existsAccount = accounts?.find(
        (item) => slugify(item.city.name.toLowerCase()) === location.id,
      );

      if (existsAccount) {
        return 'svg-map__location';
      }

      return 'svg-map__location disabled';
    },
    [accounts],
  );

  return (
    <SVGMap
      map={PuertoRicoMap}
      className="puerto-rico-map"
      isLocationSelected={(location) => location.name === selectedLocation}
      onLocationClick={handleOnClick}
      locationClassName={locationClassName}
    />
  );
};

export default PuertoRico;

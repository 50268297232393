// @flow strict

import type { Node } from 'react';
import { Link } from 'react-router-dom';
import {
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavbarToggler,
  Row,
} from 'reactstrap';
import { BiX } from '../atoms/icons';
import Logo from '../atoms/Logo';
import LogoMobile from '../atoms/LogoMobile';
import LogoEspaciosAbiertos from '../atoms/LogoEspaciosAbiertos';

type Props = {
  isOpen: boolean,
  toggle: () => void,
};

const MenuModal = function ({ isOpen, toggle }: Props): Node {
  return (
    <Modal id="main-menu-modal" isOpen={isOpen} toggle={toggle} fullscreen>
      <ModalHeader className="d-block">
        <Row>
          <Col xs={6}>
            <div className="d-none d-md-block d-lg-block">
              <Logo secondary width="130" />
            </div>
            <div className="d-block d-md-none d-lg-none mt-3">
              <LogoMobile secondary />
            </div>
          </Col>
          <Col xs={6} className="text-end">
            <NavbarToggler onClick={toggle} className="border-0">
              <BiX size={54} />
            </NavbarToggler>
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <div id="main-menu" className="mb-5">
          <ul className="list-unstyled">
            <li className="mb-4">
              <Link to="/about-us" className="display-3 fw-bold">
                Sobre nosotros
              </Link>
            </li>
            <li className="mb-4">
              <Link to="/links" className="display-3 fw-bold">
                Enlaces de interés
              </Link>
            </li>
            <li className="mb-4">
              <Link to="/faq" className="display-3 fw-bold">
                Preguntas frecuentes
              </Link>
            </li>
          </ul>
        </div>
        <p className="text-center">
          <a
            href="https://www.observatoriofiscalpr.com"
            className="btn btn-light btn-lg btn-purple btn-round fw-bold"
            style={{ lineHeight: '28px' }}
            target="_blank"
            rel="noreferrer"
          >
            OBSERVATORIO FISCAL
          </a>
        </p>
        <p className="text-center">
          <a
            href="https://www.quienmerepresentapr.com"
            className="btn btn-light btn-lg btn-purple btn-round fw-bold"
            style={{ lineHeight: '28px' }}
            target="_blank"
            rel="noreferrer"
          >
            QUIEN ME REPRESENTA
          </a>
        </p>
        <p className="text-center">
          <a
            href="http://mimunicipio.cabildoabiertopr.com"
            className="btn btn-light btn-lg btn-purple btn-round fw-bold"
            style={{ lineHeight: '28px' }}
            target="_blank"
            rel="noreferrer"
          >
            INICIAR SESIÓN
          </a>
        </p>
      </ModalBody>
      <ModalFooter className="d-block">
        <Row>
          <Col md={6}>
            <LogoEspaciosAbiertos secondary width="134" />
          </Col>
          <Col md={6}>
            <p className="text-md-end mt-3">Cataño, Puerto Rico</p>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

export default MenuModal;

// @flow strict

import axios from 'axios';
import { QMR_API_BASE_URL } from './config';

// $FlowFixMe
const qmrApiClient = axios.create({
  baseURL: QMR_API_BASE_URL,
});

export default qmrApiClient;

// @flow strict

import type { Node } from 'react';
import { useCallback } from 'react';
import { Button } from 'reactstrap';
import cx from 'classnames';

type Props = {
  id: number,
  name: string,
  active?: boolean,
  onClick?: (id: string) => void,
};

const DocumentTypeItem = function ({
  id,
  name,
  active = false,
  onClick,
}: Props): Node {
  const handleOnClick = useCallback(() => {
    onClick?.(active === true ? '' : id.toString());
  }, [id, active, onClick]);

  return (
    <li
      className={cx('list-inline-item document-type-item', {
        'document-type-item-active': active,
      })}
    >
      <Button color="link" onClick={handleOnClick}>
        {name}
      </Button>
    </li>
  );
};

export default DocumentTypeItem;

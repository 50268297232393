// @flow strict

import type { Node } from 'react';
import { useCallback, useState, useMemo, useRef } from 'react';
import { Button, Input, InputGroup } from 'reactstrap';

import { BiSearchAlt2 } from './icons';

type Option = {| label: string, value: string | number |};

type Props = {|
  onChange?: (option: ?Option) => void,
  options?: Option[],
  placeholder?: string,
  value?: ?(string | number),
|};

const CitySelector = function ({
  onChange,
  placeholder,
  options = [],
  value,
}: Props): Node {
  const listWrapper = useRef();
  const [filter, setFilter] = useState('');

  const handleOnChangeFilter = useCallback((event) => {
    setFilter(event.target.value);
  }, []);

  const handleOnScroll = useCallback((event) => {
    if (listWrapper.current != null) {
      const reachBottom =
        event.target.scrollHeight - event.target.scrollTop ===
        event.target.clientHeight;
      if (reachBottom) {
        listWrapper.current.className = 'city-list-wrapper scroll-end';
      } else {
        listWrapper.current.className = 'city-list-wrapper';
      }
    }
  }, []);

  const filteredOptions = useMemo(() => {
    if (filter != null && filter.length > 0) {
      return options.filter((item) =>
        item.label.toLowerCase().startsWith(filter.toLowerCase()),
      );
    }
    return options;
  }, [filter, options]);

  return (
    <div className="city-selector">
      <InputGroup
        size="sm"
        className="input-group-round input-group-transparent"
      >
        <Input
          className="form-control-transparent"
          size="28"
          placeholder={placeholder}
          onChange={handleOnChangeFilter}
          value={filter}
        />
        <Button color="light">
          <BiSearchAlt2 size={32} />
        </Button>
      </InputGroup>
      <div className="city-list-wrapper" ref={listWrapper}>
        <ul className="city-list" onScroll={handleOnScroll}>
          {filteredOptions.map((item) => (
            <li
              className={value === item.value ? 'active' : ''}
              key={item.value}
            >
              <Button
                block
                color="transparent"
                onClick={() => {
                  onChange?.(item);
                }}
              >
                {item.label}
              </Button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CitySelector;

// @flow strict

import type { Node } from 'react';
import cx from 'classnames';

type AvatarShape = 'circle' | 'square';
type AvatarSize = 'tiny' | 'small' | 'medium' | 'large' | 'giant';

type Props = {
  src: string,
  shape?: AvatarShape,
  size?: AvatarSize,
  className?: ?string,
};

const Avatar = function ({ src, size, shape, className }: Props): Node {
  return (
    <div
      className={cx('avatar', className, {
        'avatar-circle': shape === 'circle',
        'avatar-square': shape === 'square',
        'avatar-xs': size === 'tiny',
        'avatar-sm': size === 'small',
        'avatar-md': size === 'medium',
        'avatar-lg': size === 'large',
        'avatar-xlg': size === 'giant',
      })}
      style={{ backgroundImage: `url(${src})` }}
    />
  );
};

export default Avatar;

// @flow strict

import type { Node } from 'react';
import { useState, useCallback, useMemo } from 'react';

import Header from '../organisms/partials/Header';
import MainContent from '../organisms/partials/MainContent';
import FixedSidebar from '../organisms/partials/sidebar/FixedSidebar';
import MenuContext from '../context/Menu';
import MenuModal from '../organisms/MenuModal';

type Props = {
  children: Node,
};

const Base = function ({ children }: Props): Node {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = useCallback(() => {
    setIsMenuOpen((current) => !current);
  }, []);

  const menu = useMemo(() => {
    return { isOpen: isMenuOpen, toggle: toggleMenu };
  }, [isMenuOpen, toggleMenu]);

  return (
    <MenuContext.Provider value={menu}>
      <Header />
      <FixedSidebar />
      <MainContent>{children}</MainContent>
      <MenuModal isOpen={isMenuOpen} toggle={toggleMenu} />
    </MenuContext.Provider>
  );
};

export default Base;

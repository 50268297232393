// @flow strict

import type { Node } from 'react';
import SidebarContent from './SidebarContent';

const FixedSidebar = function (): Node {
  return (
    <aside id="sidebar" className="d-none d-md-block d-lg-block">
      <SidebarContent />
    </aside>
  );
};

export default FixedSidebar;

// @flow strict

import type { Node } from 'react';

type Props = {
  children: Node,
  loading: boolean,
  loadingElement?: Node,
  error?: ?Error,
};

const QueryResult = function ({
  children,
  loading,
  loadingElement,
  error,
}: Props): Node {
  if (loading) {
    return loadingElement ?? null;
  }

  if (error != null) {
    return <p>{error.message}</p>;
  }

  return children;
};

export default QueryResult;

// @flow strict
import type { Node } from 'react';
import { useCallback } from 'react';

import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

import { usePagination, DOTS } from './paginator-hook';

type Props = {
  totalPages: number,
  currentPage: number,
  totalItems: number,
  onChange?: (page: number) => void,
};

const Paginator = function ({
  currentPage = 1,
  totalPages = 0,
  totalItems = 0,
  onChange,
}: Props): Node {
  const paginationRange = usePagination({
    totalPages,
    currentPage,
    siblingCount: 2,
  });

  const onPreviousClick = useCallback(
    (event) => {
      event.preventDefault();
      if (currentPage > 1) {
        onChange?.(currentPage - 1);
      }
    },
    [currentPage, onChange],
  );

  const onNextClick = useCallback(
    (event) => {
      event.preventDefault();
      if (currentPage < totalPages) {
        onChange?.(currentPage + 1);
      }
    },
    [currentPage, totalPages, onChange],
  );

  if (totalPages <= 0 || totalItems === 0) {
    return null;
  }

  return (
    <Pagination listClassName="justify-content-center">
      <PaginationItem disabled={currentPage <= 1}>
        <PaginationLink href="#" onClick={onPreviousClick} previous />
      </PaginationItem>
      {paginationRange.map((pageNumber, index) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return (
            <PaginationItem
              key={`page-dot-${
                index < paginationRange.length / 2 ? 'left' : 'right'
              }`}
            >
              <PaginationLink href="#">&#8230;</PaginationLink>
            </PaginationItem>
          );
        }
        // Render our Page Pills
        return (
          <PaginationItem
            key={`page-${pageNumber}`}
            active={pageNumber === currentPage}
          >
            <PaginationLink
              href="#"
              onClick={(event) => {
                event.preventDefault();
                if (pageNumber !== currentPage) {
                  onChange?.(pageNumber);
                }
              }}
            >
              {pageNumber}
            </PaginationLink>
          </PaginationItem>
        );
      })}
      <PaginationItem disabled={currentPage >= totalPages}>
        <PaginationLink href="#" next onClick={onNextClick} />
      </PaginationItem>
    </Pagination>
  );
};

export default Paginator;

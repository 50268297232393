// @flow strict

import type { Node } from 'react';
import { Table } from 'reactstrap';

import Line from '../../../atoms/shimmer/Line';

import DocumentHeaderItem from '../../../molecules/list-item/DocumentHeaderItem';
import DocumentItem from '../../../molecules/list-item/DocumentItem';

import type { Document } from '../../../types';

type Props = {
  documents?: Array<Document>,
  loading?: boolean,
};

const DocumentList = function ({
  documents = [],
  loading = false,
}: Props): Node {
  if (loading) {
    return (
      <>
        <Line height="64px" className="mb-3" />
        <Line height="64px" className="mb-3" />
        <Line height="64px" className="mb-3" />
        <Line height="64px" className="mb-3" />
        <Line height="64px" className="mb-3" />
      </>
    );
  }

  return (
    <Table responsive>
      <thead>
        <DocumentHeaderItem />
      </thead>
      <tbody>
        {documents.map((item) => (
          <DocumentItem
            key={item.id}
            name={item.name}
            description={item.description}
            author={item.author}
            serie={item.serie}
            url={item.url}
            tags={item.tags}
          />
        ))}
        {documents.length === 0 && (
          <tr className="document-item">
            <td colSpan="4">
              <p className="mb-0">
                Aún no tenemos datos de documentos para este municipio.
              </p>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default DocumentList;

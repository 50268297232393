// @flow strict

import type { Node } from 'react';

const DocumentHeaderItem = function (): Node {
  return (
    <tr className="document-item document-header-item">
      <th>Nombre del documento</th>
      <th>Temas</th>
      <th>Presentado por</th>
      <th className="document-item-date">Serie</th>
      <th className="document-item-actions">&nbsp;</th>
    </tr>
  );
};

export default DocumentHeaderItem;

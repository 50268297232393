// @flow strict

import type { Node } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import Divider from '../../atoms/Divider';
import { PUBLIC_URL } from '../../config';

const Footer = function (): Node {
  return (
    <footer id="footer">
      <Container fluid>
        <Row>
          <Col lg={4}>
            <ul className="list-unstyled" style={{ marginBottom: '16px' }}>
              <li>
                <Link to="/about-us">Sobre nosotros</Link>
              </li>
              <li>
                <Link to="/links">Enlaces de interés</Link>
              </li>
              <li>
                <Link to="/faq">Preguntas frecuentes</Link>
              </li>
            </ul>
          </Col>
          <Col lg={4}>
            <p>
              <a
                href="https://www.quienmerepresentapr.com"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={`${PUBLIC_URL}/img/qmr-light.png`}
                  alt="Quien me representa"
                  className="img-fluid mb-3"
                  srcSet={`${PUBLIC_URL}/img/qmr-light@2x.png 2x, ${PUBLIC_URL}/img/qmr-light@3x.png 3x`}
                />
              </a>
            </p>
          </Col>
          <Col lg={4}>
            <p>
              <a
                href="https://www.observatoriofiscalpr.com"
                rel="noreferrer"
                target="_blank"
              >
                <img
                  src={`${PUBLIC_URL}/img/observatorio-fiscal-light.png`}
                  alt="Observatorio fiscal"
                  className="img-fluid mb-3"
                  srcSet={`${PUBLIC_URL}/img/observatorio-fiscal-light@2x.png 2x, ${PUBLIC_URL}/img/observatorio-fiscal-light@3x.png 3x`}
                  width="256"
                  style={{ marginTop: '7px' }}
                />
              </a>
            </p>
          </Col>
        </Row>
        <Divider height="4px" color="#ffffff" className="mt-0 mb-5" />
        <p className="copyright">
          © Cabildo Abierto PR {new Date().getFullYear()} |{' '}
          <a
            href="/docs/Politica-de-privacidad.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Política de privacidad
          </a>{' '}
          |{' '}
          <a
            href="/docs/Terminos-y-condiciones de-uso.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Términos y condiciones
          </a>
        </p>
      </Container>
    </footer>
  );
};

export default Footer;

// @flow strict

import type { Node } from 'react';
import { useEffect } from 'react';
import { Col, Row } from 'reactstrap';

import Hero from '../templates/Hero';
import { PUBLIC_URL } from '../config';

const Home = function (): Node {
  useEffect(() => {
    document.title = 'Cabildo Abierto';
  }, []);

  return (
    <Hero backgroundImage={`url(${PUBLIC_URL}/img/bghero.png)`}>
      <div style={{ margin: '12% 0' }}>
        <Row>
          <Col xs={8} md={6}>
            <h4 className="text-light mb-4">
              <b>Cabildo Abierto</b> es una plataforma creada con el propósito
              de acercar al público en Puerto Rico a sus representantes electos
              a nivel municipal e incentivar la participación cívica en procesos
              democráticos municipales. Conoce cuándo son las próximas
              actividades legislativas de tu municipio de residencia y accede a
              documentos con las decisiones de las legislaturas municipales.
            </h4>
          </Col>
        </Row>
      </div>
    </Hero>
  );
};

export default Home;

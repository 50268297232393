// @flow strict

import type { Node } from 'react';

type Props = {
  children: Node,
};

const MainContent = function ({ children }: Props): Node {
  return (
    <main id="main">
      <div className="p-4">{children}</div>
    </main>
  );
};

export default MainContent;

// @flow strict

export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'hh:mmA';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mmA';
export const DOCUMENT_DATE_TIME_FORMAT = 'DD MMMM, YYYY hh:mm a';

export const API_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const CITIES = [
  {
    id: 1,
    name: 'Adjuntas',
    abbreviation: 'ADJ',
  },
  {
    id: 3,
    name: 'Aguada',
    abbreviation: 'AGD',
  },
  {
    id: 5,
    name: 'Aguadilla',
    abbreviation: 'AGL',
  },
  {
    id: 7,
    name: 'Aguas Buenas',
    abbreviation: 'ABU',
  },
  {
    id: 9,
    name: 'Aibonito',
    abbreviation: 'AIB',
  },
  {
    id: 11,
    name: 'Añasco',
    abbreviation: 'ANA',
  },
  {
    id: 13,
    name: 'Arecibo',
    abbreviation: 'ARE',
  },
  {
    id: 15,
    name: 'Arroyo',
    abbreviation: 'ARR',
  },
  {
    id: 17,
    name: 'Barceloneta',
    abbreviation: 'BCL',
  },
  {
    id: 19,
    name: 'Barranquitas',
    abbreviation: 'BQT',
  },
  {
    id: 21,
    name: 'Bayamón',
    abbreviation: 'BAY',
  },
  {
    id: 23,
    name: 'Cabo Rojo',
    abbreviation: 'CAB',
  },
  {
    id: 25,
    name: 'Caguas',
    abbreviation: 'CAG',
  },
  {
    id: 27,
    name: 'Camuy',
    abbreviation: 'CAM',
  },
  {
    id: 29,
    name: 'Canóvanas',
    abbreviation: 'CAN',
  },
  {
    id: 31,
    name: 'Carolina',
    abbreviation: 'CAR',
  },
  {
    id: 33,
    name: 'Cataño',
    abbreviation: 'CAT',
  },
  {
    id: 35,
    name: 'Cayey',
    abbreviation: 'CAY',
  },
  {
    id: 37,
    name: 'Ceiba',
    abbreviation: 'CEI',
  },
  {
    id: 39,
    name: 'Ciales',
    abbreviation: 'CIA',
  },
  {
    id: 41,
    name: 'Cidra',
    abbreviation: 'CID',
  },
  {
    id: 43,
    name: 'Coamo',
    abbreviation: 'COA',
  },
  {
    id: 45,
    name: 'Comerío',
    abbreviation: 'COM',
  },
  {
    id: 47,
    name: 'Corozal',
    abbreviation: 'COR',
  },
  {
    id: 49,
    name: 'Culebra',
    abbreviation: 'CUL',
  },
  {
    id: 51,
    name: 'Dorado',
    abbreviation: 'DOR',
  },
  {
    id: 53,
    name: 'Fajardo',
    abbreviation: 'FAJ',
  },
  {
    id: 54,
    name: 'Florida',
    abbreviation: 'FLO',
  },
  {
    id: 55,
    name: 'Guánica',
    abbreviation: 'GNC',
  },
  {
    id: 57,
    name: 'Guayama',
    abbreviation: 'GYM',
  },
  {
    id: 59,
    name: 'Guayanilla',
    abbreviation: 'GYL',
  },
  {
    id: 61,
    name: 'Guaynabo',
    abbreviation: 'GYB',
  },
  {
    id: 63,
    name: 'Gurabo',
    abbreviation: 'GUR',
  },
  {
    id: 65,
    name: 'Hatillo',
    abbreviation: 'HAT',
  },
  {
    id: 67,
    name: 'Hormigueros',
    abbreviation: 'HOR',
  },
  {
    id: 69,
    name: 'Humacao',
    abbreviation: 'HUM',
  },
  {
    id: 71,
    name: 'Isabela',
    abbreviation: 'ISA',
  },
  {
    id: 73,
    name: 'Jayuya',
    abbreviation: 'JAY',
  },
  {
    id: 75,
    name: 'Juana Díaz',
    abbreviation: 'JUA',
  },
  {
    id: 77,
    name: 'Juncos',
    abbreviation: 'JUN',
  },
  {
    id: 79,
    name: 'Lajas',
    abbreviation: 'LAJ',
  },
  {
    id: 81,
    name: 'Lares',
    abbreviation: 'LAR',
  },
  {
    id: 83,
    name: 'Las Marías',
    abbreviation: 'LMA',
  },
  {
    id: 85,
    name: 'Las Piedras',
    abbreviation: 'LPI',
  },
  {
    id: 87,
    name: 'Loíza',
    abbreviation: 'LOI',
  },
  {
    id: 89,
    name: 'Luquillo',
    abbreviation: 'LUQ',
  },
  {
    id: 91,
    name: 'Manatí',
    abbreviation: 'MAN',
  },
  {
    id: 93,
    name: 'Maricao',
    abbreviation: 'MAR',
  },
  {
    id: 95,
    name: 'Maunabo',
    abbreviation: 'MAU',
  },
  {
    id: 97,
    name: 'Mayagüez',
    abbreviation: 'MAY',
  },
  {
    id: 99,
    name: 'Moca',
    abbreviation: 'MOC',
  },
  {
    id: 101,
    name: 'Morovis',
    abbreviation: 'MOR',
  },
  {
    id: 103,
    name: 'Naguabo',
    abbreviation: 'NAG',
  },
  {
    id: 105,
    name: 'Naranjito',
    abbreviation: 'NAR',
  },
  {
    id: 107,
    name: 'Orocovis',
    abbreviation: 'ORO',
  },
  {
    id: 109,
    name: 'Patillas',
    abbreviation: 'PAT',
  },
  {
    id: 111,
    name: 'Peñuelas',
    abbreviation: 'PEN',
  },
  {
    id: 113,
    name: 'Ponce',
    abbreviation: 'PON',
  },
  {
    id: 115,
    name: 'Quebradillas',
    abbreviation: 'QUE',
  },
  {
    id: 117,
    name: 'Rincón',
    abbreviation: 'RIN',
  },
  {
    id: 119,
    name: 'Río Grande',
    abbreviation: 'RGR',
  },
  {
    id: 121,
    name: 'Sabana Grande',
    abbreviation: 'SGR',
  },
  {
    id: 123,
    name: 'Salinas',
    abbreviation: 'SAL',
  },
  {
    id: 125,
    name: 'San Germán',
    abbreviation: 'SGE',
  },
  {
    id: 127,
    name: 'San Juan',
    abbreviation: 'SJU',
  },
  {
    id: 129,
    name: 'San Lorenzo',
    abbreviation: 'SLO',
  },
  {
    id: 131,
    name: 'San Sebastián',
    abbreviation: 'SSE',
  },
  {
    id: 133,
    name: 'Santa Isabel',
    abbreviation: 'SIS',
  },
  {
    id: 135,
    name: 'Toa Alta',
    abbreviation: 'TAL',
  },
  {
    id: 137,
    name: 'Toa Baja',
    abbreviation: 'TBA',
  },
  {
    id: 139,
    name: 'Trujillo Alto',
    abbreviation: 'TRU',
  },
  {
    id: 141,
    name: 'Utuado',
    abbreviation: 'UTU',
  },
  {
    id: 143,
    name: 'Vega Alta',
    abbreviation: 'VAL',
  },
  {
    id: 145,
    name: 'Vega Baja',
    abbreviation: 'VBA',
  },
  {
    id: 147,
    name: 'Vieques',
    abbreviation: 'VIE',
  },
  {
    id: 149,
    name: 'Villalba',
    abbreviation: 'VIL',
  },
  {
    id: 151,
    name: 'Yabucoa',
    abbreviation: 'YAB',
  },
  {
    id: 153,
    name: 'Yauco',
    abbreviation: 'YAU',
  },
];

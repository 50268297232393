// @flow strict

import type { Node } from 'react';
import { useEffect, useState } from 'react';

import List from './List';
import Paginator from '../../../molecules/paginator';

import { useAccountCalendars, useMayor } from '../../../hooks';

type Props = {
  accountId: number,
};

const CalendarList = function ({ accountId }: Props): Node {
  const [page, setPage] = useState(1);
  const { data: mayor } = useMayor(accountId);
  const { data, loading } = useAccountCalendars(accountId, { page });

  const { data: calendars, pageInfo } = data || {};

  useEffect(() => {
    setPage(1);
  }, [accountId]);

  return (
    <div>
      <List events={calendars} loading={loading} />
      {calendars.length === 0 && !loading && (
        <div className="calendar-row">
          <p>
            Queremos incluir todos los datos de tu municipio. Actualmente no
            tenemos acceso a su calendario de reuniones. Déjale saber a tu
            alcalde que quisieras conocer cuándo se reúne tu legislatura
            municipal. Escríbele en este enlace.{' '}
            <a
              href={`mailto:${
                mayor?.email ?? ''
              }?bcc=admin@espaciosabiertos.org&subject=Queremos Cabildo Abierto en nuestro municipio&body=Estimados señores, queremos conocer sobre las reuniones, actividades y decisiones de nuestra legislatura municipal. Solicitamos que esa información se publique y que también se facilite al equipo de Espacios Abiertos para incluirla en la plataforma de Cabildo Abierto.`}
              style={{ color: 'inherit' }}
            >
              {mayor?.email}
            </a>
          </p>
        </div>
      )}
      <Paginator
        currentPage={page}
        totalPages={pageInfo.totalPages}
        totalItems={pageInfo.totalItems}
        onChange={setPage}
      />
    </div>
  );
};

export default CalendarList;

// @flow strict

import type { Node } from 'react';
import { useCallback, useState } from 'react';
import Line from '../../../atoms/shimmer/Line';
import EventItem from '../../../molecules/list-item/EventItem';
import EventModal from '../../../molecules/modal/EventModal';

import type { Event } from '../../../types';

type Props = {
  events: Array<Event>,
  loading?: boolean,
};

const CalendarList = function ({ events = [], loading = false }: Props): Node {
  const [isOpen, setIsOpen] = useState(false);
  const [event, setEvent] = useState({});

  const handleOnClick = useCallback((data) => {
    setIsOpen((current) => !current);
    setEvent(data);
  }, []);

  const toggleModal = useCallback(() => {
    setIsOpen((current) => !current);
  }, []);

  if (loading) {
    return (
      <>
        <Line height="64px" className="mb-3" />
        <Line height="64px" className="mb-3" />
        <Line height="64px" className="mb-3" />
        <Line height="64px" className="mb-3" />
        <Line height="64px" className="mb-3" />
      </>
    );
  }

  return (
    <div>
      {events.map((item) => (
        <div key={item.id} className="event-item">
          <EventItem
            id={item.id}
            name={item.name}
            description={item.description}
            from={item.from}
            to={item.to}
            status={item.status}
            attachments={item.attachments}
            onClick={handleOnClick}
          />
        </div>
      ))}
      <EventModal
        name={event.name}
        description={event.description}
        from={event.from}
        to={event.to}
        isOpen={isOpen}
        toggle={toggleModal}
      />
    </div>
  );
};

export default CalendarList;

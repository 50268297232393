// @flow strict

import type { Node } from 'react';

type Props = {
  title: string,
  description?: string,
  avatar?: Node,
};

const AvatarItem = function ({ title, description, avatar }: Props): Node {
  return (
    <div className="d-flex align-items-center avatar-legislator">
      <div>{avatar}</div>
      <div className="ms-2">
        <h5 className="fw-bold lh-1 mb-0">{title}</h5>
        <small className="fst-italic text-capitalize avatar-legislator-position">
          {description}
        </small>
      </div>
    </div>
  );
};

export default AvatarItem;

// @flow strict

import type { Node } from 'react';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavbarText,
  NavbarToggler,
  UncontrolledTooltip,
} from 'reactstrap';
// import cx from 'classnames';
import LogoMobile from '../../atoms/LogoMobile';
import CityAutocomplete from '../../atoms/CityAutocomplete';
import { BiMenu } from '../../atoms/icons';
import { useCities } from '../../hooks';
import MenuContext from '../../context/Menu';

const Header = function (): Node {
  const navigate = useNavigate();
  const { data: cities } = useCities();
  const { toggle } = useContext(MenuContext);

  const cityOptions = cities?.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const handleOnChangeCity = useCallback(
    (option) => {
      if (option?.value != null) {
        navigate(`/town/${option.value}`);
      }
    },
    [navigate],
  );

  return (
    <header id="header">
      <Navbar>
        <Nav className="align-items-center">
          <NavItem className="d-block d-md-none d-lg-none">
            <NavbarBrand tag="div">
              <LogoMobile secondary />
            </NavbarBrand>
          </NavItem>
          <NavItem className="me-3 d-none d-lg-block">
            <a
              href="https://www.observatoriofiscalpr.com/municipios/"
              className="btn btn-light btn-purple btn-round btn-sm fw-bold px-3 my-1"
              style={{ lineHeight: '28px', fontSize: '1rem' }}
              target="_blank"
              rel="noreferrer"
              id="obsfiscallnk"
            >
              OBSERVATORIO FISCAL
            </a>
          </NavItem>
          <NavItem className="me-3 d-none d-lg-block">
            <a
              href="https://www.quienmerepresentapr.com"
              className="btn btn-light btn-purple btn-round btn-sm fw-bold px-3 my-1"
              style={{ lineHeight: '28px', fontSize: '1rem' }}
              target="_blank"
              rel="noreferrer"
              id="qmrlnk"
            >
              QUIEN ME REPRESENTA
            </a>
          </NavItem>
          <NavItem className="me-3 d-none d-lg-block">
            <a
              href="http://mimunicipio.cabildoabiertopr.com"
              className="btn btn-light btn-purple btn-round btn-sm fw-bold px-3 my-1"
              style={{ lineHeight: '28px', fontSize: '1rem' }}
              target="_blank"
              rel="noreferrer"
            >
              INICIAR SESIÓN
            </a>
          </NavItem>
        </Nav>
        <NavbarText style={{ paddingBottom: '0.3rem', paddingTop: '0.3rem' }}>
          {/*
          <NavbarToggler onClick={toggle}>
            <BiAdjust size={40} />
          </NavbarToggler>
          */}
          <NavbarToggler onClick={toggle}>
            <BiMenu size={40} />
          </NavbarToggler>
        </NavbarText>
      </Navbar>
      <UncontrolledTooltip placement="bottom" target="obsfiscallnk">
        Vea el presupuesto de su municipio
      </UncontrolledTooltip>
      <UncontrolledTooltip placement="bottom" target="qmrlnk">
        Vea quiénes son los funcionarios que le representan y contáctelos
      </UncontrolledTooltip>
      <div className="px-2 d-block d-md-none d-lg-none pb-2">
        <CityAutocomplete
          onChange={handleOnChangeCity}
          options={cityOptions}
          placeholder="Busca tu Municipio"
        />
      </div>
    </header>
  );
};

export default Header;

// @flow strict

import type { Node } from 'react';
import ReactReadMoreReadLess from 'react-read-more-read-less';

import { BiDownload } from '../../atoms/icons';

type Props = {
  name: string,
  description: string,
  author: string,
  serie: string,
  url: string,
  tags: string,
};

const DocumentItem = function ({
  name,
  description,
  author,
  serie,
  url,
  tags,
}: Props): Node {
  return (
    <tr className="document-item">
      <td style={{ width: '46%' }}>
        <div className="d-flex">
          <div>
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              style={{ color: 'inherit' }}
              className="me-3"
            >
              <BiDownload size={28} />
            </a>
          </div>
          <div className="flex-fill">
            <h6 className="document-name fw-bold">{name}</h6>
            {description && (
              <p>
                <span className="fw-bold me-2">Descripción.-</span>
                <ReactReadMoreReadLess
                  charLimit={150}
                  readMoreText="Leer más"
                  readLessText="Leer menos"
                >
                  {description}
                </ReactReadMoreReadLess>
              </p>
            )}
          </div>
        </div>
      </td>
      <td style={{ width: '26%' }}>{tags}</td>
      <td>{author}</td>
      <td className="document-item-date">{serie}</td>
      <td className="actions" />
    </tr>
  );
};

export default DocumentItem;

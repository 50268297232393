// @flow strict

import type { Node } from 'react';
import { useEffect } from 'react';
import { Col, Row } from 'reactstrap';

import Divider from '../atoms/Divider';
import Base from '../templates/Base';
import { PUBLIC_URL } from '../config';

const AboutUsPage = function (): Node {
  useEffect(() => {
    document.title = 'Cabildo Abierto - Sobre Nosotros';
  }, []);

  return (
    <Base>
      <h5 className="fw-bold">SOBRE NOSOTROS</h5>
      <Divider className="my-3" />
      <Row>
        <Col md="6">
          <img
            alt="Espacios Abiertos"
            className="img-fluid mb-3"
            src={`${PUBLIC_URL}/img/home.png`}
          />
        </Col>
        <Col md="6">
          <h5 className="fw-bold mb-4">Espacios Abiertos</h5>
          <p>
            Es una organización sin fines de lucro e independiente, que desde su
            inicio en 2014 busca crear mecanismos que fortalezcan la
            participación cívica para que la gente en Puerto Rico tenga voz y
            sea parte de las decisiones que se toman en el país.
          </p>
          <p>
            Aspiramos a una sociedad más abierta y democrática que resulte a su
            vez en un Puerto Rico más justo y equitativo. A esos fines, Espacios
            Abiertos procura que el gobierno y sus instrumentalidades rindan
            cuentas sobre su gestión a la ciudadanía, con énfasis en la
            transparencia del uso de los fondos públicos.
          </p>
          <p>
            Para más información visita{' '}
            <a
              href="https://espaciosabiertos.org"
              target="_blank"
              rel="noreferrer"
              style={{ color: 'inherit' }}
            >
              espaciosabiertos.org
            </a>{' '}
            o síguenos en Facebook, X e Instagram. Te invitamos a visitar otras
            de nuestras herramientas digitales: el{' '}
            <a
              href="http://observatoriofiscalpr.com"
              target="_blank"
              rel="noreferrer"
              style={{ color: 'inherit' }}
            >
              Observatorio Fiscal
            </a>{' '}
            y{' '}
            <a
              href="http://quienmerepresentapr.com"
              target="_blank"
              rel="noreferrer"
              style={{ color: 'inherit' }}
            >
              Quien Me Representa
            </a>
            .
          </p>
        </Col>
      </Row>
      <Divider className="my-5" />
      <Row>
        <Col md="6">
          <h5 className="fw-bold mb-4">Cabildo Abierto</h5>
          <p>
            Los municipios son la estructura de gobierno más cercana a la gente,
            y mientras la mayoría de las personas reconoce quién ocupa la
            posición de alcalde o alcaldesa de su municipio, pocos conocen o
            saben quiénes son, cómo contactar, cuándo y dónde se reúnen, y qué
            proponen o deciden sus legisladores y legisladoras municipales.
          </p>
          <p>
            <strong>Cabildo Abierto</strong>, tiene el propósito de fomentar
            mayor conocimiento y participación del público en la toma de
            decisiones a nivel del gobierno municipal, acercando a la gente a su
            legislatura municipal. <strong>Cabildo Abierto</strong> procura
            promover que las decisiones sobre los recursos municipales sean
            transparentes y participativas y que además, sirvan a las
            necesidades y las prioridades de la mayoría de los residentes del
            municipio, sobre bases de justicia y equidad para todas las
            personas.
          </p>
          <p>
            Todas las sugerencias de las personas que hacen uso de esta
            plataforma enriquecen nuestro trabajo. Si deseas contactarnos puedes
            escribirnos a{' '}
            <a
              href="mailto:info@espaciosabiertos.org"
              style={{ color: 'inherit' }}
            >
              info@espaciosabiertos.org
            </a>
          </p>
        </Col>
        <Col md="6">
          <img
            alt="Cabildo Abierto"
            className="img-fluid"
            src={`${PUBLIC_URL}/img/home.png`}
          />
        </Col>
      </Row>
      <Divider className="my-5" />
      <div className="p-4">
        <h5 className="fw-bold mb-4">Reconocimiento</h5>
        <p>
          El diseño y desarrollo de esta plataforma ha contado con el trabajo y
          talento de jóvenes puertorriqueños y empresas puertorriqueñas como
          Microjuris. La realización de Cabildo Abierto la hizo posible con una
          subvención inicial del Fondo Adelante de Filantropía Puerto Rico y
          otra subvención del Democracy, Ethics and Public Trust Initiative de
          The Henry Luce Foundation. El generoso respaldo de éstos y de todos
          nuestros donantes son clave para el trabajo de Espacios Abiertos y su
          equipo. Gracias.
        </p>
        <div className="text-center">
          <img
            alt="Henry Loce Foundation"
            className="mb-3 align-bottom mx-2"
            src={`${PUBLIC_URL}/img/donantes/henry-luce.jpg`}
            width="270"
          />
          <img
            alt="Filantropia Puerto Rico"
            className="mb-3 mx-2"
            src={`${PUBLIC_URL}/img/donantes/filantropia.png`}
            width="170"
          />
        </div>
      </div>
    </Base>
  );
};

export default AboutUsPage;

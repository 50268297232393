// @flow strict

import type { Node } from 'react';

import Base from '../templates/Base';

const NotFound = function (): Node {
  return (
    <Base>
      <h1>Esta página no está disponible.</h1>
    </Base>
  );
};

export default NotFound;

// @flow strict

import type { Node } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';

import AboutUs from './pages/AboutUs';
import Faq from './pages/Faq';
import Home from './pages/Home';
import Links from './pages/Links';
import NotFound from './pages/NotFound';
import Town from './pages/Town';

moment.locale('es');

const App = function (): Node {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/links" element={<Links />} />
        <Route path="/town/:id" element={<Town />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

// @flow strict

import type { Node } from 'react';

import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CitySelector from '../../../atoms/CitySelector';
import Logo from '../../../atoms/Logo';
import LogoEspaciosAbiertos from '../../../atoms/LogoEspaciosAbiertos';
import Divider from '../../../atoms/Divider';
import { useAccounts } from '../../../hooks';

const SidebarContent = function (): Node {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: accounts } = useAccounts();

  const accountOptions = useMemo(() => {
    return accounts
      ?.map((item) => ({
        label: item.city.name,
        value: item.id,
      }))
      .sort((a, b) => {
        const fa = a.label.toLowerCase();
        const fb = b.label.toLowerCase();
        if (fa < fb) {
          return -1;
        }

        if (fa > fb) {
          return 1;
        }
        return 0;
      });
  }, [accounts]);

  const handleOnChangeCity = useCallback(
    (option) => {
      if (option?.value != null) {
        navigate(`/town/${option.value}`);
      }
    },
    [navigate],
  );

  return (
    <>
      <div className="py-3 px-4">
        <div className="text-center mb-5">
          <Logo width="130" />
        </div>
        {/* <Divider height={1} /> */}
        {/* <p className="fw-bold">Es aut la corporum</p>
        <p>
          Terrum hil ea que quae millupt atemper ovitem fuga. Ovid eaquundenim
          renis experunt, conem.
        </p> */}
        <Divider height="3px" />
        <CitySelector
          onChange={handleOnChangeCity}
          options={accountOptions}
          placeholder="Busca tu Municipio"
          value={id != null ? parseInt(id, 10) : 0}
        />
      </div>
      <div className="position-absolute bottom-0 w-100">
        <div className="py-3 px-4">
          <Divider height="1px" />
          <div className="px-3 text-center">
            <LogoEspaciosAbiertos width="134" />
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarContent;

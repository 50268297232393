// @flow strict

import { useEffect, useState } from 'react';
import apiClient from './api-client';
import qmrApiClient from './qmr-client';
import { CITIES } from './constants';

import type {
  Account,
  City,
  DocumentType,
  Event,
  Legislator,
  Paginator,
  QueryResult,
  Town,
  DocumentResponse,
  CalendarResponse,
  Tag,
} from './types';

const EMPTY_PAGE_INFO = {
  totalItems: 0,
  itemsPerPage: 0,
  totalPages: 0,
  currentPage: 1,
};

export const useAccounts = (): QueryResult<Array<Account>> => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    apiClient
      .get('/accounts/accounttypes/3')
      .then((response) => {
        setData(
          response.data.data.map((item) => ({
            id: item.id,
            name: item.name,
            city: {
              id: item.city_code,
              name: item.city_name,
            },
          })),
        );
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { data, loading, error };
};

export const useCity = (id: number): QueryResult<City> => {
  const data = CITIES.find((item) => item.id === id) || {};
  return { data, loading: false, error: null };
};

export const useCities = (): QueryResult<City[]> => {
  const data = CITIES;

  return { data, loading: false, error: null };
};

export const useTown = (id: number): QueryResult<Town> => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    setLoading(true);

    apiClient
      .get(`/accounts/${id}`)
      .then((response) => {
        return Promise.all([
          response,
          qmrApiClient.get(
            `/legislators?municipality=${response.data.data[0].city_name}`,
          ),
          qmrApiClient.get(
            `/legislators?politician_type=alcalde&municipality=${response.data.data[0].city_name}`,
          ),
        ]);
      })
      .then(([response, legislators, mayors]) => {
        if (response.data.success) {
          const towns = response.data.data;

          if (Array.isArray(towns) && towns.length > 0) {
            const town = towns[0];
            setData({
              id,
              name: town.city_name || '',
              description: town.name || '',
              mayor:
                mayors.data.data.map((item, index) => ({
                  id: index,
                  name: `${item.first_name} ${item.last_name}`,
                  gender: item.gender,
                  position: item.position,
                  avatar: item.avatar,
                  url: item.url,
                  party: item.party,
                  email: item.email,
                }))?.[0] ?? {},
              legislators: legislators.data.data.map((item, index) => ({
                id: index,
                name: `${item.first_name} ${item.last_name}`,
                gender: item.gender,
                position: item.position,
                avatar: item.avatar,
                url: item.url,
                party: item.party,
                email: item.email,
              })),
            });
          } else {
            const town = response.data.data;
            // Set default data
            setData({
              id,
              name: town?.city_name ?? '',
              description: '',
              mayor:
                mayors.data.data.map((item, index) => ({
                  id: index,
                  name: `${item.first_name} ${item.last_name}`,
                  gender: item.gender,
                  position: item.position,
                  avatar: item.avatar,
                  url: item.url,
                  party: item.party,
                }))?.[0] ?? {},
              legislators: legislators.data.data.map((item, index) => ({
                id: index,
                name: `${item.first_name} ${item.last_name}`,
                gender: item.gender,
                position: item.position,
                avatar: item.avatar,
                url: item.url,
                party: item.party,
              })),
            });
          }
        } else {
          setError(new Error('No encontramos datos'));
        }
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return { data, loading, error };
};

export const useMayor = (id: number): QueryResult<Legislator> => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    setLoading(true);

    apiClient
      .get(`/accounts/${id}`)
      .then((response) => {
        return qmrApiClient.get(
          `/legislators?politician_type=alcalde&municipality=${response.data.data[0].city_name}`,
        );
      })
      .then((mayors) => {
        setData(
          mayors.data.data.map((item, index) => ({
            id: index,
            name: `${item.first_name} ${item.last_name}`,
            gender: item.gender,
            position: item.position,
            avatar: item.avatar,
            url: item.url,
            party: item.party,
            email: item.email,
          }))?.[0] ?? {},
        );
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  return { data, loading, error };
};

export const useAccountCalendars = (
  accountId: number,
  options: Paginator = { page: 1 },
): QueryResult<CalendarResponse> => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState({ data: [], pageInfo: EMPTY_PAGE_INFO });

  useEffect(() => {
    setLoading(true);
    const queryparams = `page=${options.page}`;

    apiClient
      .get(`/calendarentries/accounts/${accountId}?${queryparams}`)
      .then((response) => {
        if (response.data.success) {
          const calendars = response.data.data;
          setData({
            data: calendars.map((item) => ({
              id: item.id,
              name: item.name,
              description: item.description,
              status: item.status,
              from: item.date_from,
              to: item.date_to,
              attachments: item.attachments?.map((attachment) => ({
                name: attachment.name,
                url: attachment.file_url,
              })),
              location: item.location,
            })),
            pageInfo: {
              totalItems: response.data.meta.totalItems,
              itemsPerPage: response.data.meta.itemsPerPage,
              totalPages: response.data.meta.totalPages,
              currentPage: response.data.meta.currentPage,
            },
          });
        } else {
          setError(new Error('No encontramos datos'));
        }
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [accountId, options.page]);

  return { data, loading, error };
};

export const useAccountDocuments = (
  accountId: number,
  filter: { documentType?: string, tag?: string },
  options: Paginator = { page: 1 },
): QueryResult<DocumentResponse> => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState({ data: [], pageInfo: EMPTY_PAGE_INFO });

  useEffect(() => {
    setLoading(true);
    let queryparams = `page=${options.page}`;

    if (filter.documentType != null && filter.documentType !== '') {
      queryparams += `&document_type_code=${filter.documentType}`;
    }

    if (filter.tag != null && filter.tag !== '') {
      queryparams += `&document_tag_code=${filter.tag}`;
    }

    apiClient
      .get(`/documents/accounts/${accountId}?${queryparams}`)
      .then((response) => {
        if (response.data.success) {
          const documents = response.data.data;

          if (Array.isArray(documents)) {
            setData({
              data: documents.map((item) => ({
                id: item.id,
                name: item.name,
                description: item.description,
                author: item.document_presenter_name,
                modifiedAt: item.updated_at,
                url: item.file_url,
                document_type_id: item.document_type_code,
                serie: item.period_name,
                tags: item.tags_name?.join(', ') ?? '',
              })),
              pageInfo: {
                totalItems: response.data.meta.totalItems,
                itemsPerPage: response.data.meta.itemsPerPage,
                totalPages: response.data.meta.totalPages,
                currentPage: response.data.meta.currentPage,
              },
            });
          } else {
            setData({
              data: [],
              pageInfo: EMPTY_PAGE_INFO,
            });
          }
        } else {
          setError(new Error('No encontramos datos'));
        }
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [accountId, filter.documentType, filter.tag, options.page]);

  return { data, loading, error };
};

export const useCalendarEntries = (
  calendarId: number,
): QueryResult<Array<Event>> => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    apiClient
      .get(`/calendarentries/${calendarId}`)
      .then((response) => {
        if (Array.isArray(response.data.data)) {
          setData(
            response.data.data.map((item) => ({
              id: item.id,
              name: item.name,
              description: item.description,
              status: item.status,
              from: item.date_from,
              to: item.date_to,
              attachments: item.attachments?.map((attachment) => ({
                name: attachment.name,
                url: attachment.file_url,
              })),
              location: '',
            })),
          );
        } else {
          const calendarEntry = response.data.data;
          setData([
            {
              id: calendarEntry.id,
              name: calendarEntry.name,
              description: calendarEntry.description,
              status: calendarEntry.status,
              from: calendarEntry.date_from,
              to: calendarEntry.date_to,
              attachments: calendarEntry.attachments?.map((attachment) => ({
                name: attachment.name,
                url: attachment.file_url,
              })),
              location: '',
            },
          ]);
        }
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [calendarId]);

  return { data, loading, error };
};

export const useDocumentTypes = (): QueryResult<Array<DocumentType>> => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    setData([
      {
        id: 3,
        name: 'Ordenanza Municipal',
      },
      // {
      //   id: 5,
      //   name: 'Orden Ejecutiva',
      // },
      // {
      //   id: 2,
      //   name: 'Proyecto de Ordenanza',
      // },
      {
        id: 4,
        name: 'Resolución Municipal',
      },
      // {
      //   id: 1,
      //   name: 'Proyecto de Resolución',
      // },
    ]);
    setLoading(false);
  }, []);

  return { data, loading, error: null };
};

export const useTags = (): QueryResult<Array<Tag>> => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    setData([
      {
        id: 29,
        name: 'Administración de Cementerios',
      },
      {
        id: 1,
        name: 'Administración Municipal',
      },
      {
        id: 16,
        name: 'Adultos Mayores ',
      },
      {
        id: 21,
        name: 'Agricultura y Seguridad Alimentaria',
      },
      {
        id: 47,
        name: 'Agua y Alcantarillados',
      },
      {
        id: 2,
        name: 'Alianzas Público Privadas',
      },
      {
        id: 12,
        name: 'Ambiente',
      },
      {
        id: 3,
        name: 'Asuntos Federales',
      },
      {
        id: 4,
        name: 'Auspicios',
      },
      {
        id: 15,
        name: 'Bienestar Público (Servicios)',
      },
      {
        id: 50,
        name: 'Códigos de Orden Público',
      },
      {
        id: 23,
        name: 'Comercios',
      },
      {
        id: 6,
        name: 'Consorcios',
      },
      {
        id: 5,
        name: 'Contratos',
      },
      {
        id: 51,
        name: 'Controles Físicos de Velocidad en las Vías y Carreteras Municipales',
      },
      {
        id: 22,
        name: 'Corporaciones Municipales',
      },
      {
        id: 43,
        name: 'Cuerpo de Emergencias Médicas',
      },
      {
        id: 58,
        name: 'Cultura',
      },
      {
        id: 20,
        name: 'Desarrollo Económico',
      },
      {
        id: 60,
        name: 'Designación',
      },
      {
        id: 13,
        name: 'Desperdicios Sólidos y Saneamiento',
      },
      {
        id: 38,
        name: 'Donativos',
      },
      {
        id: 25,
        name: 'Educación',
      },
      {
        id: 24,
        name: 'Empresas Municipales y Franquicias Comerciales',
      },
      {
        id: 37,
        name: 'Empréstitos / Préstamos',
      },
      {
        id: 48,
        name: 'Energía',
      },
      {
        id: 17,
        name: 'Familias',
      },
      {
        id: 61,
        name: 'Felicitaciones',
      },
      {
        id: 39,
        name: 'Incentivos',
      },
      {
        id: 28,
        name: 'Infraestructura ',
      },
      {
        id: 7,
        name: 'Legislatura Municipal',
      },
      {
        id: 52,
        name: 'Manejo de Emergencias y Administración de Desastres',
      },
      {
        id: 53,
        name: 'Multas Administrativas',
      },
      {
        id: 54,
        name: 'Multas Penales',
      },
      {
        id: 18,
        name: 'Niñez Temprana',
      },
      {
        id: 30,
        name: 'Obras Públicas',
      },
      {
        id: 33,
        name: 'Ordenación Territorial',
      },
      {
        id: 8,
        name: 'Organización Administrativa',
      },
      {
        id: 59,
        name: 'Otros',
      },
      {
        id: 40,
        name: 'Patentes',
      },
      {
        id: 34,
        name: 'Permisos',
      },
      {
        id: 32,
        name: 'Planificación y Urbanismo',
      },
      {
        id: 19,
        name: 'Población con Diversidad Funcional',
      },
      {
        id: 9,
        name: 'Poder Ejecutivo / Alcaldía',
      },
      {
        id: 62,
        name: 'Poder Ejecutivo / Alcaldía',
      },
      {
        id: 55,
        name: 'Policía Municipal',
      },
      {
        id: 36,
        name: 'Presupuesto / Finanzas Municipales',
      },
      {
        id: 10,
        name: 'Procesos Judiciales (Municipio como parte)',
      },
      {
        id: 41,
        name: 'Propiedad Municipal (Arrendamiento/Venta)',
      },
      {
        id: 63,
        name: 'Reconocimientos',
      },
      {
        id: 64,
        name: 'Recreación y Deportes',
      },
      {
        id: 11,
        name: 'Recursos Humanos',
      },
      {
        id: 44,
        name: 'Refugios de Animales',
      },
      {
        id: 42,
        name: 'Salud ',
      },
      {
        id: 14,
        name: 'Salud Ambiental',
      },
      {
        id: 35,
        name: 'Segregaciones',
      },
      {
        id: 49,
        name: 'Seguridad',
      },
      {
        id: 45,
        name: 'Servicios de salud',
      },
      {
        id: 26,
        name: 'Servicios educativos',
      },
      {
        id: 46,
        name: 'Servicios Públicos/ Utilidades',
      },
      {
        id: 65,
        name: 'Transportación',
      },
      {
        id: 27,
        name: 'Transportación Escolar',
      },
      {
        id: 57,
        name: 'Turismo',
      },
      {
        id: 56,
        name: 'Vehículos y Tránsito',
      },
      {
        id: 31,
        name: 'Vivienda',
      },
    ]);
    setLoading(false);
  }, []);

  return { data, loading, error: null };
};

export const useUpcomingEvents = (): QueryResult<Array<Event>> => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    apiClient
      .get('/calendarentries')
      .then((response) => {
        setData(
          response.data.data.map((item) => ({
            id: item.id,
            name: item.name,
            description: item.description,
            status: item.status,
            from: item.date_from,
            to: item.date_to,
            attachments: item.attachments?.map((attachment) => ({
              name: attachment.name,
              url: attachment.file_url,
            })),
            location: item.location,
          })),
        );
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { data, loading, error };
};

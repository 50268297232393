// @flow strict

import type { Node } from 'react';
import { useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from 'reactstrap';

import Base from '../templates/Base';
import CalendarList from '../organisms/list/CalendarList';
import DocumentList from '../organisms/list/DocumentList';
import Town from '../organisms/Town';

const TownPage = function (): Node {
  const { id } = useParams();

  return (
    <Base>
      <Town id={parseInt(id, 10)} />
      <Accordion className="accordion-cabildo" open={['1', '2']}>
        <AccordionItem className="no-caret">
          <AccordionHeader targetId="2">
            CALENDARIO DE REUNIONES Y EVENTOS LEGISLATURA MUNICIPAL
          </AccordionHeader>
          <AccordionBody accordionId="2">
            <CalendarList accountId={id} />
          </AccordionBody>
        </AccordionItem>
        <AccordionItem className="no-caret">
          <AccordionHeader targetId="1">DOCUMENTOS</AccordionHeader>
          <AccordionBody accordionId="1">
            <DocumentList accountId={id} />
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </Base>
  );
};

export default TownPage;

// @flow strict

import type { Node } from 'react';

import { Link } from 'react-router-dom';

import { PUBLIC_URL } from '../config';

type Props = {
  secondary?: boolean,
};

const LogoMobile = function ({ secondary = false }: Props): Node {
  const logo = secondary ? 'logo-mobile-light' : 'logo-mobile';
  const logoPath = `${PUBLIC_URL}/img/${logo}`;

  return (
    <Link to="/">
      <img
        src={`${logoPath}.png`}
        alt="Cabildo abierto"
        className="img-fluid"
        width="50"
        srcSet={`${logoPath}@2x.png 2x, ${logoPath}@3x.png 3x`}
      />
    </Link>
  );
};

export default LogoMobile;
